/*
 * @Author: 刘康宁 869161591@qq.com
 * @Date: 2022-08-23 10:02:04
 * @LastEditors: YGQ
 * @LastEditTime: 2024-04-22 11:39:12
 * @FilePath: /oneid/src/api/request.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import axios from 'axios'
import cookie from 'js-cookie'
import Vue from 'vue'
import router from '../router'
import host from './host'

// 请求拦截器
axios.interceptors.request.use(config => {
  let browserId = Vue.prototype.$browserId
  if (!browserId) {
    browserId = localStorage.getItem('browserId') // 存储浏览器指纹，在项目中用于校验用户身份和埋点
  }
  config.headers['Browser-Id'] = browserId  // 在每个请求中添加浏览器指纹
  if (!browserId) {
    Vue.prototype.$aegis.reportEvent(`未获取到浏览器指纹`);
  }
  return config;
});

/**
 * axios 请求
 * @param {String} api 请求的api接口
 * @param {Object} params 请求参数
 * @return {Function} 请求成功后继续处理数据
 */
const get = (api, params = {}) => request(api, params, 'get')
const post = (api, params = {}) => request(api, params, 'post')
async function request (api, params = {}, method = 'get') {
  try {
    const url = host.getHost().host + api
    let config = {} // 用于文件上传时, 传入参数为 FormData 格式, 需额外设置 Content-Type
    if (Object.prototype.toString.call(params) === '[object FormData]') {
      config = { headers: { 'Content-Type': 'multipart/form-data' } }
    } else {
      params = _requestFit(params) // 请求前的数据适配
    }
    const response = method == 'post' ? await axios.post(url, params, config) : await axios.get(url, { params })
    console.log('response', response);
    const data = _responseFit(response?.data) // 对返回数据适配
    _printLog(api, params, response)
    const errMsg = typeof data.msg == 'string' ? data.msg : JSON.stringify(data.msg)
    return data.ok ? [data.data, null] : [data.data, errMsg] // 正常时取出data返回, 异常时返回信息, 格式均为 [res, err]
  } catch (err) {
    if (err == 'Network Error') return Vue.prototype.$msg('协议不安全, 请将网址中的 http 改为 https 再登录');
    Vue.prototype.$msg.error(err)
  }
}

// 请求发送前的数据适配 (驼峰转下划线)
function _requestFit (data) {
  return _camel2snake(data)
}

// 请求返回后的数据适配 (下划线转驼峰)
function _responseFit (data) {
  if (data.code == 2001) {
    const isHome = ['/'].includes(window.location.pathname)
    cookie.remove('TOKEN')
    if (isHome) return _snake2camel(data)
    return router.replace('/') // 2001未登录
  }
  return _snake2camel(data)
}

// 下划线转驼峰
function _snake2camel (data) {
  if (typeof data !== 'object' || !data) return data
  if (Array.isArray(data)) return data.map((item) => _snake2camel(item))
  const fitData = {}
  for (const key in data) {
    const newKey = key.replace(/_([a-z])/g, (p, m) => m.toUpperCase())
    fitData[newKey] = _snake2camel(data[key])
  }
  return fitData
}
// 驼峰转下划线
function _camel2snake (data) {
  if (typeof data !== 'object' || !data) return data
  if (Array.isArray(data)) return data.map((item) => _camel2snake(item))
  const fitData = {}
  for (const key in data) {
    const newKey = key.replace(/([A-Z])/g, (p, m) => `_${m.toLowerCase()}`)
    fitData[newKey] = _camel2snake(data[key])
  }
  return fitData
}

// 控制台打印返回
function _printLog (api, params, data) {
  if (Vue.prototype.$ENV !== 'release') {
    console.group(api, params)
    if (Vue.prototype.$ENV === 'develop') console.log(data) // 开发环境下, 控制台打印返回
    if (Vue.prototype.$ENV === 'trial') console.log(params, JSON.stringify(params))
    console.groupEnd()
  }
  return true
}

// 将请求方法注入全局 (在 main.js 里面 use 本文件)
const install = () => {
  Vue.prototype.$post = post
  Vue.prototype.$get = get
}

export default { install }
