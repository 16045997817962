/*
 * @Description: 
 * @Version: 2.0
 * @Autor: YGQ
 * @Date: 2023-03-30 16:51:42
 * @LastEditors: YGQ
 * @LastEditTime: 2024-01-16 16:05:22
 */
import Vue from "vue";
import Aegis from 'aegis-web-sdk';
import host from '@/api/host';
const version = require('../package.json').version
const HOST_CODE = host.getHost().env

const environment = {
  release: 'production',
  develop: 'development',
  trial: 'pre',
  local: 'local',
}

const ENV = environment[HOST_CODE] || 'others'

const aegis = new Aegis({
  version,
  id: 'ZEmdGfvdZ9o5RnwJZY', // 上报 id
  env: ENV,// 区分环境
  spa: true, // spa 应用页面跳转的时候开启 pv 计算
  hostUrl: 'https://rumt-zh.com',
  websocketHack: true,
  reportApiSpeed: true, // 接口测速
  reportAssetSpeed: true, // 静态资源测速
  api: {
    apiDetail: true, // 上报接口请求参数和返回值
    reportRequest: true, // 全量接口上报
  },
});

const install = () => {
  Vue.prototype.$aegis = aegis
};

export default {
  install,
};
