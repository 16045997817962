/*
 * @Author: 刘康宁 869161591@qq.com
 * @Date: 2022-08-23 10:07:01
 * @LastEditors: YGQ
 * @LastEditTime: 2023-12-04 14:21:48
 * @FilePath: /oneid/src/main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import request from './api/request.js' // 封装的 http 请求接口
import util from './api/util.js' // 封装的工具类接口
import vant from './api/vant.js' // vant 组件库
import element from './api/element.js' // Element 组件库
import 'element-ui/lib/theme-chalk/display.css'; // Element 自适应样式
import './styles/index.css' // 全局样式库
import VueClipboard from 'vue-clipboard2' // 剪贴板插件
import aegisWebSdk from "./aegisWebSdk.JS"; // 腾讯云监控


Vue.config.productionTip = false
Vue.use(request) // 封装的 http 请求接口
Vue.use(util) // 封装的工具类接口
Vue.use(vant) // Vant 组件
Vue.use(element) // Element 组件
Vue.use(VueClipboard)
Vue.use(aegisWebSdk)

new Vue({
  router,
  render: (h) => h(App)
}).$mount('#app')
