/*
 * @Author: 刘康宁 869161591@qq.com
 * @Date: 2022-08-23 10:07:01
 * @LastEditors: 刘康宁 869161591@qq.com
 * @LastEditTime: 2022-08-23 10:38:11
 * @FilePath: /oneid/src/api/vant.js
 * @Description: 按需引入 Vant 组件
 */
import {
    Button,
    Field,
    Form,
    Toast
} from 'vant'
const vant = {
    install: function (Vue) {
        Vue.use(Button)
        Vue.use(Field)
        Vue.use(Form)
        Vue.use(Toast)
    }
}
export default vant
