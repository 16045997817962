<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import Fingerprint2 from "fingerprintjs2";
import Vue from 'vue'
export default {
  name: "app",
  mounted() {
    Fingerprint2.get((components) => {
      // 参数只有回调函数时，默认浏览器指纹依据所有配置信息进行生成
      const values = components.map((component) => component.value); // 配置的值的数组
      const murmur = Fingerprint2.x64hash128(values.join(""), 31); // 生成浏览器指纹
      localStorage.setItem("browserId", murmur); // 存储浏览器指纹，在项目中用于校验用户身份和埋点
      Vue.prototype.$browserId = murmur; // 存储浏览器指纹，在项目中用于校验用户身份和埋点
      console.log(murmur);
    });
  },
};
</script>
 
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
/* 禁止用户选中文本 */
* {
  -webkit-user-select: none; /* webkit浏览器 */
  -khtml-user-select: none; /* 早期浏览器 */
  -moz-user-select: none; /* 火狐 */
  -ms-user-select: none; /* IE10 */
  user-select: none; /* 禁止用户选中文本 */
}
/* 允许用户输入文本 */
input,
textarea {
  -webkit-user-select: text; /* webkit浏览器 */
  -khtml-user-select: text; /* 早期浏览器 */
  -moz-user-select: text; /* 火狐 */
  -ms-user-select: text; /* IE10 */
  user-select: text; /* 允许用户输入文本 */
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}
</style>
