/*
 * @Author: 刘康宁 869161591@qq.com
 * @Date: 2022-07-21 10:10:23
 * @LastEditors: 刘康宁 869161591@qq.com
 * @LastEditTime: 2023-04-14 12:00:23
 * @FilePath: /oneid/src/router/index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import cookie from 'js-cookie'

Vue.use(VueRouter)

const routes = [
  { path: '/', name: 'login', component: () => import('../views/login/login.vue') },
  { path: '/sso', name: 'sso', component: () => import('../views/login/sso.vue') },
  { path: '/dashboard', name: 'dashboard', component: () => import('../views/home/dashboard.vue') },
  { path: '/user', name: 'user', component: () => import('../views/home/user.vue') },
  // { path: '/404', name: '404', component: () => import('../views/error/404.vue') },
  { path: '*', redirect: '/' }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// 全局导航守卫
router.beforeEach((to, from, next) => {
  // 访问的是登录页面 就放行
  if (to.path === '/') return next()
  // 获取用户的 token
  const token = cookie.get('TOKEN')
  const user = JSON.parse(localStorage.getItem('user')) // 必须同时有 token 和用户 name 才判断为已登录
  if (!(token || to.query?.token || user?.name)) return next({ path: '/', query: to.query }) // 重定向到登录页 
  next() // 正常跳转
})

export default router
